define("discourse/plugins/discourse-change-owner/discourse/components/reviewable-field-user", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    actions: {
      userChanged(previouslySelected, selected) {
        this.valueChanged && this.valueChanged({
          target: {
            value: selected[0]
          }
        });
      }
    }
  });
});